'use client';

import { Button, Container, Flex, Text } from '@chakra-ui/react';
import Link from 'next/link';
import { MdHome, MdRefresh } from 'react-icons/md';

export default function Error({ error, reset }: { error: Error; reset: () => void }) {
    return (
        <Container centerContent>
            <Flex
                height="screen"
                width="full"
                direction="column"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                gap={6}
                p={6}
            >
                <Text fontSize="lg" fontWeight="medium">
                    Something went wrong
                </Text>

                <Text color="red.500">{error?.message}</Text>

                <Flex wrap="wrap" gap={4} justifyContent="center">
                    <Button size="sm" onClick={reset}>
                        <MdRefresh /> Refresh
                    </Button>
                    <Link href="/" passHref>
                        <Button as="a" size="sm">
                            <MdHome />
                            Home
                        </Button>
                    </Link>
                </Flex>
            </Flex>
        </Container>
    );
}
